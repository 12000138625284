.list-group-item{
  padding: 0 !important;
  margin: 0.5rem 0.75rem;
  border: 0 !important;
 }

.form-md-editor{
  margin: 30px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}
.form-file-upload{
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;

}

.block-menu-trigger {
  left: -40px !important;
}

.postIcon {
  padding: 3px;
  border: 1px dashed #152889;
  color: #152889;
  border-radius: 3px;
  margin-right: 5px;
}

.dashBox{
  border: 1px dashed;
  border-radius: 10px;
  padding: 10px;
}
